.preview {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.preview-body {
  height: 100vh;
  width: 100vw;
  background-color: black;
}

.image-gallery {
  height: auto;
  width: 100vw;
}

.image-gallery-image {
  object-fit: contain;
  object-position: center;
  background-color: black;
  max-height: 80vh;
  max-width: 100vw;
  width: auto;
  height: 100%;
}

.uploading-indicator {
  padding-top: 40vh;
}

.upload-failed p {
  color: white;
}

.upload-failed {
  padding-top: 35vh;
}

.upload-failed h1 {
  color: white;
}

.upload-failed p {
  color: white;
  text-align: center;
  width: 80vw;
  padding-bottom: 25vh;
}

.caching-failed p {
  color: white;
}

.caching-failed {
  padding-top: 35vh;
}

.caching-failed h1 {
  color: white;
}

.caching-failed p {
  color: white;
  text-align: center;
  width: 80vw;
  padding-bottom: 25vh;
}
