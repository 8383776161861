#bottom-bar {
  flex: 1 1 auto;
  position: absolute;
  bottom: 5vh;
  left: 50%;
  transform: translateX(-50%);
}

#big-logo {
  height: 20%;
  box-sizing: border-box;
  margin-top: 10vh;
  margin-bottom: 10vh;
  padding: 0;
}
