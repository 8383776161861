.settings-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

#close {
  position: absolute;
  margin-left: 20px;
  margin-top: 20px;
}

.logout-button {
  padding-top: 10vh;
}
