/* GPT go brr */
/* General Styles */
body {
  font-family: "Arial", sans-serif; /* Modern font */
  background-color: #f4f4f4; /* Light background for a clean look */
  color: #333; /* Dark grey text for readability */
  line-height: 1.6; /* Spacing between lines */
}

/* Help Component Styles */
.help-container {
  max-width: 800px; /* Limit the width for better readability */
  margin: 40px auto; /* Center the container with margin */
  padding: 20px; /* Padding inside the container */
  background-color: #fff; /* White background for the content area */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  border-radius: 8px; /* Slightly rounded corners */
}

h1,
h2 {
  color: #007bff; /* A modern, vibrant color for headings */
}

h1 {
  margin-bottom: 20px; /* Space below the main heading */
}

h2 {
  margin-top: 30px; /* Space above subheadings */
  margin-bottom: 10px; /* Space below subheadings */
}

ul {
  list-style-type: none; /* Remove default list styling */
  padding: 0; /* Remove default padding */
}

ul li {
  background-color: #e7f1ff; /* Light background for list items */
  margin-bottom: 10px; /* Space between list items */
  padding: 10px; /* Padding inside list items */
  border-radius: 5px; /* Slightly rounded corners for list items */
}

ul li:hover {
  background-color: #d0e7ff; /* Slightly darker on hover for interactivity */
}

.answer {
  background-color: #f9f9f9; /* Slightly different background for answers */
  margin-top: 5px; /* Small space above the answer */
  padding: 8px; /* Padding inside the answer block */
  border-left: 3px solid #007bff; /* Highlight line for visual distinction */
  border-radius: 3px; /* Slightly rounded corners for answers */
  font-style: italic; /* Italicize the answer text */
}
