.camera {
  position: relative;
  display: flex;
  flex-flow: column;
  height: 100vh;
  width: 100vw;
  background-color: black;
}

.camera-feed {
  width: 100vw;
  height: 80vh;
}

.camera-warning {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  margin: 1rem;
  background-color: #333;
  border-radius: 1rem;
  border: none;
  color: white;
}
